.nav-panel--brands .nav-logo {
  background-color: transparent;
  flex-shrink: 0;
  height: 35px;
  margin-bottom: .25rem;
  margin-right: .5rem;
  overflow: hidden;
  width: 35px;
  display: flex;
  align-items: center;
}

.nav-panel--brands .nav-panel__inner a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-panel--brands .nav-panel__inner .shop-all {
  border: none;
  margin-top: 50px;
}

.nav-panel--brands .nav-links {
  flex: 1;
}
