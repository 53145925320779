.nav-usp {
  align-items: center;
  background-color: #fef667;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  border-top: 1px solid #e8e8e8;
}

.nav-usp a {
  color: #505050;
  display: block;
  flex-basis: 33.33%;
  font-weight: 600;
  margin: 0;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  white-space: normal;
}

.nav-usp a:hover {
  color: #000;
}

.nav-usp .jd-now-usp__container {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  width: 100%;
}

.nav-usp .jd-now-usp__logo {
  height: 100%;
  margin-right: 1rem;
  max-width: 5rem;
}

.nav-usp .jd-now-usp__text {
  font-size: 1rem;
  font-weight: 400;
  padding: .25rem;
  text-transform: none;
}

.nav-usp .jd-now-usp__text span {
  font-size: 1rem;
  font-weight: 900;
}
