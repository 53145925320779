.nav-links .img-and-text-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
}

.nav-link__img {
  max-height: 2rem;
  max-width: 2rem;
  display: block;
  margin-right: 1rem;
}

.world-cup__headshot {
  max-width: 3.25rem;
  max-height: 3.25rem;
}

.double-column__container {
  display: flex;
  flex-direction: row;
}

.no-after {
  cursor: default;
}

.no-after::after {
  content: '';
  display: none;
}

.shop-section {
  max-width: 13.5%;
}

@media only screen and (min-width: 1366px) {
  .shop-section {
    max-width: 25%;
  }
}
