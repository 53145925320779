.nav-panel--new-in {
  min-height: auto;
}

.nav-panel--new-in .nav-panel__inner {
  display: block;
  margin: 0 auto;
  max-width: 1380px;
  text-align: center;
}

.nav-panel--new-in .nav-promotion {
  display: inline-block;
  margin: 0 .25rem;
  padding-bottom: calc(90.1% / 4);
  position: relative;
  width: calc(25% - 1rem);
}

.nav-panel--new-in .nav-promotion__img {
  width: 100%;
  display: block;
}

.nav-panel--new-in .nav-promotion span {
  background-color: #1a1a1a;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  left: 0;
  opacity: .8;
  padding: .5rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

